import IndexedDbRepository from '@common/dbStorage/indexedDbStore';

/* eslint-disable no-undef */
export default class ItemDataStore {
	store: IndexedDbRepository<any>;

	/**
	 * Create an instance of the ItemDataStore class
	 * This will be used to store all dynamic data for the message item
	 * Should be clear once the item is sent
	 */
	constructor(storeName: string) {
		this.store = new IndexedDbRepository(storeName ?? 'item-data-store', 604800000);
	}

	/**
	 * Retrieves the value with the given key
	 * @param {string} key The key of the message to retrieve
	 * @returns {object | StorageError}
	 */
	async getItemData(key: string): Promise<any | null> {
		const found = await this.store.get(key);
		if (!found) return null;
		return found;
	}

	/**
	 * Updates the db value of with the given key
	 * @param key The key of the message item to update
	 * @param {object} data The value(s) to updated
	 * @returns {void | StorageError}
	 */
	async updateItemData(
		key: string,
		data: object
	): Promise<void> {
		// Get the latest data and append the new data object
		const itemData = await this.getItemData(key);
		await this.store.update(key, { ...itemData, ...data });
	}

	/**
	 * Removes the value(s) with the given key from the store.
	 * @param {string} key The key the current message
	 * @returns {void | StorageError}
	 */
	async removeItemData(key: string): Promise<void> {
		await this.store.remove(key);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
