import * as types from '@/store/types';
import { AuthenticationInterface } from '@/interfaces';

const getDefaultState = () => ({
	isAuthenticated: false,
	isAuthenticating: false,
	token: null,
	error: ''
});

export default {
	[types.LOGIN_REQUESTED](state: AuthenticationInterface): void {
		state.isAuthenticating = true;
		state.error = '';
	},
	[types.LOGIN_SUCCESS](state: AuthenticationInterface, payload: any): void {
		const { data, user } = payload;
		state.isAuthenticating = false;
		state.isAuthenticated = true;
		state.token = data;
		state.authUser = user;
	},
	[types.CLIENT_STATE_REFRESH_REQUESTED](state: AuthenticationInterface): void {
		state.isAuthenticating = true;
		state.error = '';
	},
	[types.CLIENT_STATE_REFRESH_SUCCESS](state: AuthenticationInterface, payload: any): void {
		const {
			isAuthenticated, user, data
		} = payload;
		state.authUser = user;
		state.token = data;
		state.isAuthenticated = isAuthenticated;
		state.isAuthenticating = false;
	},
	[types.LOGIN_FAILED](state: AuthenticationInterface, payload: any): void {
		state.isAuthenticating = false;
		state.error = payload;
	},
	[types.LOGIN_RESET_ERROR](state: AuthenticationInterface): void {
		state.error = '';
	},
	[types.LOGOUT_REQUESTED](state: AuthenticationInterface): void {
		state.isAuthenticating = true;
		state.error = '';
	},
	[types.LOGOUT_SUCCESS](state: AuthenticationInterface): void {
		Object.assign(state, getDefaultState());
	},
	[types.LOGOUT_FAILED](state: AuthenticationInterface, payload: any): void {
		state.isAuthenticating = false;
		state.error = payload;
	}
};
