class Timer {
	name: string;

	startTime: number;

	stopTime: number;

	isRunning: boolean;

	constructor(name: string) {
		this.name = name;
		this.startTime = 0;
		this.stopTime = 0;
		this.isRunning = false;
	}

	static startNew(name: string): Timer {
		const result = new Timer(name);
		result.start();
		return result;
	}

	start(): void {
		if (this.isRunning) return;
		this.startTime = performance.now();
		this.isRunning = true;
	}

	stop(): void {
		if (!this.isRunning) return;
		this.stopTime = performance.now();
		this.isRunning = false;
	}

	resume(): void {
		if (this.isRunning) return;
		this.startTime += performance.now() - this.stopTime;
		this.isRunning = true;
	}

	pause(): void {
		this.stop();
	}

	completed(): boolean {
		return this.stopTime > 0;
	}

	getElapsed(): number {
		if (this.isRunning) return performance.now() - this.startTime;
		return this.stopTime - this.startTime;
	}
}

export default Timer;
