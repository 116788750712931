import ApiError from '@common/errors/apiError';
import FetchError from '@common/errors/fetchError';
import { buildGetRequestOption } from '@common/httpClient/buildRequestOptions';

const getMessageMimeContentAsync = async (
	messageUrl: string,
	restToken: string
): Promise<any> => {
	try {
		const response = await fetch(
			messageUrl,
			buildGetRequestOption(restToken, { Prefer: 'outlook.body-content-type="text"' })
		);

		const responseData = await response?.json();

		// Handle failures
		if (!response.ok) {
			throw new ApiError(
				`Loading MIME message failed with a non-2xx status code. StatusCode:[${response.status}].`,
				responseData?.localizationId,
				responseData?.code,
				responseData?.userEventId
			);
		}

		return responseData;
	} catch (err) {
		if (err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent', 'api.errors.fetchFailed');
	}
};

export default getMessageMimeContentAsync;
