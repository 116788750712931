import Vue from 'vue';
import Vuex from 'vuex';

// Import vuex modules
import { clientStateIsAuthenticated, getUserLabels, getUserState } from '@common/utils/clientState';
import { getEsiTokenDetails } from '@common/token';
import { LOGOUT_SUCCESS, STORE_REST_ID } from '@/store/types';
import { StoreInterface } from '@/interfaces';
import authModule from './modules/auth';
import userModule from './modules/user';
import labelsModule from './modules/labels';
import rbpModule from './modules/rbp';
import appInformationModule from './modules/appInformation';

Vue.use(Vuex);
export default async function createVueStore(options: any) {
	const clientStateAuthenticated = await clientStateIsAuthenticated();
	let userState;
	let userLabels;
	let token;

	if (clientStateAuthenticated) {
		try {
			userState = await getUserState();
			userLabels = await getUserLabels();
			token = await getEsiTokenDetails();
		} catch (error) {
			const { errorCode, userEventId } = (error as any);
			Vue.prototype.$logger.warn({
				message: 'Failed retrieving persisted state.',
				properties: {
					source: 'store/index.ts', method: 'createVueStore', userEventId, error
				}
			}, errorCode);
		}
	}

	return new Vuex.Store({
		...options,
		state: {
			restId: ''
		},
		mutations: {
			[STORE_REST_ID](state: StoreInterface, restId) {
				state.restId = restId;
			},
			[LOGOUT_SUCCESS](state) {
				state.restId = '';
			}
		},
		modules: {
			auth: authModule({
				isAuthenticated: clientStateAuthenticated,
				token: clientStateAuthenticated ? token?.data : ''
			}),
			user: userModule(clientStateAuthenticated ? userState : {}),
			labels: labelsModule(clientStateAuthenticated ? userLabels : {}),
			rbp: rbpModule,
			appInformation: appInformationModule
		},
		strict: process.env.NODE_ENV !== 'production'
	});
}
