import { RBP_CLIENT_TYPE } from '@common/constants';
import getAppConfigInfo from '@common/httpClient/config/getAppConfigInfo';
import LogLevels from '@egress/client-logger/dist/LogLevels';
import { OfficeProxy } from '@egress/officejs-proxy';

const ResolveLogLevel = (logLevel: string): LogLevels => {
	// Setting default log level to Information
	switch (logLevel.toLowerCase()) {
		case 'debug':
			return LogLevels.Debug;
		case 'warn':
			return LogLevels.Warn;
		case 'error':
			return LogLevels.Error;
		default:
			return LogLevels.Info;
	}
};

export default function configureLogger(logger: any, user?: string): void {
	Office.onReady(() => {
		if (OfficeProxy && !OfficeProxy.isRunningInDialog()) {
			getAppConfigInfo().then((result: any) => {
				const {
					appInformation: { appVersion },
					logging: { instrumentationKey, logLevel }
				} = result;

				logger.addAppInsightsSink(instrumentationKey, RBP_CLIENT_TYPE);
				logger.setVersion(appVersion);
				logger.setLogLevel(ResolveLogLevel(logLevel));
				if (user) logger.setUser(user);
			});
		}
	});
	// Commented out the catch block to ensure launch event is triggered correctly on desktop client
	// Desktop client does not use localStorage so alway failing at the moment
	// Will look to reinstate once the storage feature across all platform is sorted out.
	// .catch((err) => {
	// 	// eslint-disable-next-line no-console
	// 	console.error('Failed initiating AppInsights logger.', err);
	// });
}
