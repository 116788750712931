/* eslint-disable no-bitwise */
import { OfficeProxy, RECIPIENT_TYPE } from '@egress/officejs-proxy';
import { getUserState } from '@common/utils/clientState';
import { getMessageBodyAsync } from '@common/utils/outlookRest';
import { CommonEmailAttributesInterface, RecipientInterface } from '@common/interfaces';
import { RbpAuditOptions } from '@common/enums';
import hash from 'object-hash';
import { ITEM_DATA_STATE, ORIGINALRECIPIENT_STORE } from '@common/constants';
import performanceLogger from '@common/performanceLogger';
import OriginalRecipientsStore from '@common/dbStorage/originalRecipientsStore';
import ItemDataStore from '@common/dbStorage/itemDataStore';
import { logger } from '@common/logger';
import { makeArrayUnique } from './conversion';

const mapOfficeRecipientsToRbp = (
	recipient: { displayName: string; emailAddress: string; },
	type: number
) => ({
	display: recipient.displayName,
	email: recipient.emailAddress,
	type
});
/**
 * gets the original recipients from custom properties of the current message
 * @returns {Promise<RecipientInterface[]>}
 */
async function getOriginalRecipientsFromCustomProps(): Promise<RecipientInterface[]> {
	try {
		const proxy = new OfficeProxy();
		const customProperties = await proxy.getItemCustomPropertiesAsync();
		const originalRecipientsRaw = customProperties.get(ORIGINALRECIPIENT_STORE);
		return JSON.parse(originalRecipientsRaw);
	} catch {
		logger.warn({
			message: 'failed to retrieve original recipients from custom props',
			properties: {
				source: 'getCommonEmailAttributes.ts', method: 'getOriginalRecipientsFromCustomProps'
			}
		});
		return [];
	}
}
const getMessageBodyContent = async () => {
	const proxy = new OfficeProxy();
	const clientInfo = OfficeProxy.getClientInformation();
	const composeType = await proxy.getItemComposeTypeAsync();
	const isNewEmail = (composeType.toLowerCase() === 'newmail');
	let bodyContent: string;

	// Use outlook REST API to retrieve msg content for Mac, OWA and new outlook clients
	// (where the message is a reply or a forward)
	if ((clientInfo.platform.toLowerCase() === 'mac' || ['outlookwebapp', 'newoutlookwindows'].includes(clientInfo.hostName.toLowerCase())) && !isNewEmail) {
		try {
			bodyContent = await getMessageBodyAsync();
			return bodyContent;
		} catch {
			logger.debug({
				message: 'failed getting message body using getMessageBodyAsync, falling back to proxy method',
				properties: { source: 'getCommonEmailAttributes.ts', method: 'getMessageBodyContent', clientInfo }
			});
		}
	}
	bodyContent = await proxy.getItemBodyAsync();
	return bodyContent;
};

const getCommonEmailAttributes = async (restId: string, switchId: string, opinionContextId: string)
: Promise<CommonEmailAttributesInterface> => {
	const timer = performanceLogger.startNew('utils.getCommonEmailAttributes');
	const proxy = new OfficeProxy();

	const to = await proxy.getItemRecipientsAsync(RECIPIENT_TYPE.to);
	const cc = await proxy.getItemRecipientsAsync(RECIPIENT_TYPE.cc);
	const bcc = await proxy.getItemRecipientsAsync(RECIPIENT_TYPE.bcc);
	const from = await proxy.getItemFromAsync();
	const subject = await proxy.getItemSubjectAsync();
	const body = await getMessageBodyContent();

	const recipients = [];
	recipients.push(...(to ?? []).map((r) => mapOfficeRecipientsToRbp(r, 1)));
	recipients.push(...(cc ?? []).map((r) => mapOfficeRecipientsToRbp(r, 2)));
	recipients.push(...(bcc ?? []).map((r) => mapOfficeRecipientsToRbp(r, 3)));

	let originalRecipients;
	const clientInfo = OfficeProxy.getClientInformation();

	if (clientInfo.platform.toLowerCase() === 'pc') {
		originalRecipients = await getOriginalRecipientsFromCustomProps();
	} else {
		const originalRecipientsStore = new OriginalRecipientsStore(ORIGINALRECIPIENT_STORE);
		originalRecipients = JSON.parse(
			await originalRecipientsStore.getOriginalRecipients(restId) || '[]'
		);
	}

	timer.stop();
	return {
		from: from.emailAddress,
		body,
		subject,
		recipients,
		sender: switchId,
		opinionContextId,
		originalRecipients
	};
};

const getNormalisedSubject = async (subject: string): Promise<string> => {
	const { auditOptions } = await getUserState();

	// eslint-disable-next-line no-nested-ternary
	return (RbpAuditOptions.Subject & auditOptions) === RbpAuditOptions.Subject
		? subject : (RbpAuditOptions.SubjectHash & auditOptions) === RbpAuditOptions.SubjectHash
			? hash(subject) : '';
};

const getAssociatedEmails = async (restId: string): Promise<string[]> => {
	const proxy = new OfficeProxy();
	const itemDataStore = new ItemDataStore(ITEM_DATA_STATE);
	const user = proxy.getUserProfile();
	const { emailAddress } = await proxy.getItemFromAsync();
	const itemData = await itemDataStore.getItemData(restId);

	const uniqueEmails = makeArrayUnique([
		itemData?.ORIGINAL_MAILBOX_PROPERTY,
		user?.emailAddress!,
		emailAddress!
	]).filter((x) => !!x);

	return uniqueEmails;
};

export { getCommonEmailAttributes, getNormalisedSubject, getAssociatedEmails };
