import { OfficeProxy } from '@egress/officejs-proxy';
import getMessageMimeContentAsync from '@common/httpClient/outlook/client';

const updateMessage = () => new Promise((resolve, reject) => {
	Office.context.mailbox.item?.saveAsync(async (asyncResult) => {
		if (asyncResult.status === Office.AsyncResultStatus.Failed) {
			reject(asyncResult.error);
		} else {
			resolve(asyncResult.value);
		}
	});
});

const getMessageBodyAsync = async (): Promise<string> => {
	try {
		const proxy = new OfficeProxy();
		const restUrl = proxy.getRestUrl();
		const restId = await proxy.getItemRestIdAsync();
		const authToken = await proxy.getRestTokenAsync();

		// Save message details to ensure getting latest copy of message
		await updateMessage();

		// formulate the messageUrl query to retrieve only message body
		const messageUrl = `${restUrl}/v2.0/me/messages/${restId}?$select=Body`;
		const message = await getMessageMimeContentAsync(messageUrl, authToken);

		return message.Body.Content;
	} catch (error) {
		// Dont throw error, best effort approach
		return '';
	}
};

export { getMessageBodyAsync, updateMessage };
