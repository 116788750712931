import Vue from 'vue';
import VueRouter from 'vue-router';
import { AuthenticationMode } from '@common/enums';
import { clientStateIsAuthenticated } from '@common/utils/clientState';
import Landing from '@/views/Landing.vue';

Vue.use(VueRouter);

// @ts-ignore
const routes = [
	{
		path: '/',
		name: 'Landing',
		props: (route: {
			query: { manifestVersion: any; };
		}) => ({
			manifestVersion: route.query.manifestVersion
		}),
		component: Landing,
		meta: {
			anonymous: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		props: (route: {
			query: { showMessage: string; mailbox: string, authMode: string };
		}) => ({
			mailbox: route.query.mailbox,
			showMessage: route.query.showMessage === 'true',
			authMode: route.query.authMode ?? AuthenticationMode.Auto
		}),
		component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/compose',
		name: 'Compose',
		component: () => import(/* webpackChunkName: "messagecompose" */ '@/views/MessageCompose.vue')
	},
	{
		path: '/app-load-failed',
		name: 'AppLoadFailedError',
		props: (route: { query: { code: any; }; }) => ({ code: route.query.code }),
		component: () => import(/* webpackChunkName: "apploadfailederror" */ '@/views/AppLoadFailedError.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/mailbox-changed',
		name: 'MailboxChangedError',
		component: () => import(/* webpackChunkName: "mailboxchangederror" */ '@/views/MailboxChangedError.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/sso/callback/:encoded',
		name: 'SsoCallback',
		props: (route: { params: { encoded: any }, query: { token: any }; }) => ({
			encoded: route.params.encoded,
			token: route.query.token
		}),
		component: () => import(/* webpackChunkName: "ssocallback" */ '@/views/sso/SsoCallback.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/sso/error',
		name: 'SsoError',
		component: () => import(/* webpackChunkName: "ssoerror" */ '@/views/sso/SsoError.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/sso/success',
		name: 'SsoSuccess',
		props: true,
		component: () => import(/* webpackChunkName: "ssosuccess" */ '@/views/sso/SsoSuccess.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/sso/redirect/:idp',
		name: 'SsoRedirect',
		props: (route: { params: { idp: any; }; }) => ({ idp: route.params.idp }),
		component: () => import(/* webpackChunkName: "ssoredirect" */ '@/views/sso/SsoRedirect.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/session-expired',
		name: 'SessionExpired',
		component: () => import(/* webpackChunkName: "sessionexpired" */ '@/views/SessionExpired.vue'),
		props: true
	},
	{
		path: '/requirements-check-failed',
		name: 'RequirementsCheckFailed',
		component: () => import(/* webpackChunkName: "requirementscheckfailed" */ '@/views/RequirementsCheckFailed.vue')
	},
	{
		path: '/diagnostics',
		name: 'Diagnostics',
		component: () => import(/* webpackChunkName: "diagnostics" */ '@/views/DiagnosticsDialog.vue')
	},
	{
		path: '/prompts/requestLabel',
		name: 'RequestLabelPrompt',
		props: (route: { query: { rlds: any, countdown: any }; }) => ({
			rlds: route.query.rlds,
			countdown: route.query.countdown
		}),
		component: () => import(/* webpackChunkName: "requestlabel" */ '@/views/dlpPrompts/RequestLabelPrompt.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/prompts/requestTag',
		name: 'RequestTagPrompt',
		props: (route: { query: { rtds: any, countdown: any }; }) => ({
			rtds: route.query.rtds,
			countdown: route.query.countdown
		}),
		component: () => import(/* webpackChunkName: "requesttag" */ '@/views/dlpPrompts/RequestTagPrompt.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/prompts/message',
		name: 'MessagePrompt',
		props: (route: { query: { msg: any, matches: any, countdown: any }; }) => ({
			msg: route.query.msg,
			matches: route.query.matches,
			countdown: route.query.countdown
		}),
		component: () => import(/* webpackChunkName: "message" */ '@/views/dlpPrompts/MessagePrompt.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/prompts/messagenotsent',
		name: 'MessageNotSent',
		component: () => import(/* webpackChunkName: "messagenotsent" */ '@/views/MessageNotSent.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/components',
		name: 'Components',
		component: () => import(/* webpackChunkName: "components" */ '@/views/Components.vue'),
		meta: {
			anonymous: true
		},
		beforeEnter: (to: any, from: any, next: (arg0?: string | undefined) => void) => {
			if (process.env.NODE_ENV === 'production') {
				next('Landing');
			} else {
				next();
			}
		}
	},
	{
		path: '/onsend/error',
		name: 'OnSendError',
		props: (route: { query: { code: any; userEventId: any; } }) => ({
			code: route.query.code, userEventId: route.query.userEventId
		}),
		component: () => import(/* webpackChunkName: "onsenderror" */ '@/views/onSendHook/OnSendError.vue')
	},
	{
		path: '*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "notfound" */ '@/views/NotFound.vue'),
		meta: {
			anonymous: true
		}
	}
];

const router = new VueRouter({
	base: '/taskpane/',
	routes
});

router.beforeEach((to, from, next) => {
	const isAnonymousRoute = to.matched.some((r) => r.meta.anonymous);

	if (!isAnonymousRoute) {
		// Protected route so check authentication
		if (!clientStateIsAuthenticated()) {
			next({ name: 'Login' });
			return;
		}
	}

	next();
});

export default router;
