<script lang='ts'>
import { mapGetters, mapActions } from 'vuex';
import { clientStateIsAuthenticated, getUserState, getUserLabels } from '@common/utils/clientState';
import { getEsiTokenDetails } from '@common/token';
import { UPDATE_OFFICE_THEME, CLIENT_STATE_REFRESH_REQUESTED } from '@/store/types';
import authMixin from '@/mixins/authMixin';

export default authMixin.extend({
	name: 'OfficeJSApp',
	data() {
		return {
			storagePoll: 0,
			officeThemePoll: 0,
			isReady: false
		};
	},
	methods: {
		...mapActions([UPDATE_OFFICE_THEME]),
		async pollStorage() {
			const clientStateAuthenticated = await clientStateIsAuthenticated();

			// Check whether the persisted clientState in localStorage shows us as logged in,
			// in which case that probably happened via the onSend dialog so we should react to it.
			if (!this.isAuthenticated && clientStateAuthenticated) {
				// Not the most elegant approach, but it works well to reload the entire vue app and
				// retrieve the user details which have now been populated, and show the taskpane in a
				// logged in state.

				let token;
				let userState;
				let userLabels;

				try {
					userState = await getUserState();
					userLabels = await getUserLabels();
					token = await getEsiTokenDetails();
					await this.$store.dispatch(CLIENT_STATE_REFRESH_REQUESTED, {
						isAuthenticated: true,
						user: userState,
						labels: userLabels,
						data: token?.data,
						expiresAtUtc: token?.expiresAtUtc
					});
				} catch (error) {
					const { errorCode, userEventId } = (error as any);
					this.$logger.warn({
						message: 'Failed retrieving persisted state.',
						properties: {
							source: 'App.vue', method: 'pollStorage', userEventId, error
						}
					}, errorCode);
				}
			}
		},
		async pollOfficeTheme() {
			await this[UPDATE_OFFICE_THEME]();
		}
	},
	computed: {
		...mapGetters(['darkModeEnabled', 'isAuthenticated'])
	},
	async mounted() {
		await this.pollStorage();
		await this.pollOfficeTheme();
		this.storagePoll = window.setInterval(this.pollStorage, 1000);
		this.officeThemePoll = window.setInterval(this.pollOfficeTheme, 1000);

		window.addEventListener('beforeunload', () => {
			clearInterval(this.storagePoll);
			clearInterval(this.officeThemePoll);
		});
	},
	beforeDestroy() {
		clearInterval(this.storagePoll);
	}
});
</script>

<template>
  <div
    id="app"
    :class="{ 'theme-dark': darkModeEnabled, 'theme-light': !darkModeEnabled }">
    <transition
      name="fade"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
html, body {
	font-size: 14px !important;
}

div#app.theme-dark {
	border-top: none;
	margin-top: unset;
}
div#app {
	border-top: 1px solid lightgray;
	margin-top: -1px;
}
</style>
