import { logout, login } from '@common/httpClient/auth/client';
import { addSeconds } from 'date-fns';
import { storeEsiToken } from '@common/token';
import { setUserState } from '@common/utils/clientState';
import { AuthenticationInterface, StoreInterface } from '@/interfaces';
import {
	LOGIN_REQUESTED,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	LOGOUT_REQUESTED,
	CLIENT_STATE_REFRESH_REQUESTED,
	CLIENT_STATE_REFRESH_SUCCESS
} from '@/store/types';

export default {
	async [LOGIN_REQUESTED]({ commit, dispatch }: any, credentials: any): Promise<void> {
		try {
			commit(LOGIN_REQUESTED);
			const response = await login(credentials);
			const { data, expiresInSeconds } = response.token;
			const { user } = response;
			const utcDate = new Date().toISOString();
			const expiresAtUtc = addSeconds(new Date(utcDate), expiresInSeconds).toISOString();
			await Promise.allSettled([
				storeEsiToken(data, expiresAtUtc),
				setUserState(user)
			]);
			await dispatch(LOGIN_SUCCESS, {
				data,
				expiresAtUtc,
				user
			});
		} catch (err) {
			commit(LOGIN_FAILED, (err as any).localizationId);
			throw err;
		}
	},
	async [LOGIN_SUCCESS]({ commit, dispatch }: any, payload: any): Promise<void> {
		const { data, expiresAtUtc, user } = payload;
		await dispatch(CLIENT_STATE_REFRESH_REQUESTED, payload);
		commit(LOGIN_SUCCESS, {
			data,
			expiresAtUtc,
			user
		});
	},
	async [LOGOUT_REQUESTED]({ commit, state, rootState }
		: {commit: any, state: AuthenticationInterface, rootState: StoreInterface}): Promise<void> {
		const { switchId } = rootState.user;
		const { token } = state;

		commit(LOGOUT_REQUESTED);
		await logout(switchId, token);
	},
	async [CLIENT_STATE_REFRESH_REQUESTED]({ commit }: any, payload: any): Promise<void> {
		commit(CLIENT_STATE_REFRESH_REQUESTED);
		const { data, expiresAtUtc, user } = payload;
		await Promise.allSettled([
			storeEsiToken(data, expiresAtUtc),
			setUserState(user)
		]);
		commit(CLIENT_STATE_REFRESH_SUCCESS, payload);
	}
};
