import { OfficeProxy } from '@egress/officejs-proxy';
import { SWITCH_HEADER } from '@common/constants';
import { getElementFromSwitchHeader } from '@common/utils/switchHeader';
import { logger } from '@common/logger';
import {
	ExtendedInternetHeaders,
	MessagePropertiesInterface,
	OpinionRequestBodyInterface
} from '@common/interfaces';
import { getNormalisedSubject } from '@common/utils/getCommonEmailAttributes';

/**
 * Build a request body for making RBP Opinion requests
 * @param {Object} msgProperties Standard message properties
 */
export default async (
	msgProperties: MessagePropertiesInterface
): Promise<OpinionRequestBodyInterface> => {
	const requestBody: OpinionRequestBodyInterface = {};

	const proxy = new OfficeProxy();

	let selectedLabelId: string | null = null;
	// eslint-disable-next-line max-len
	const headers =	<ExtendedInternetHeaders> await proxy.getItemInternetHeadersAsync([SWITCH_HEADER]);

	if (headers?.[SWITCH_HEADER]) {
		selectedLabelId = getElementFromSwitchHeader(headers[SWITCH_HEADER], 'labelId');
	}
	const itemSubject = await getNormalisedSubject(msgProperties.subject!);

	requestBody.commonEmailAttributes = {
		opinionContextId: msgProperties.opinionContextId,
		messageId: msgProperties.restId!,
		sender: msgProperties.switchId!,
		from: msgProperties.from!,
		recipients: msgProperties.recipients!,
		subject: itemSubject,
		body: msgProperties.body,
		originalRecipients: msgProperties.originalRecipients
	};

	requestBody.inReplyTo = proxy.getItemInReplyTo();

	try {
		requestBody.conversationIndex = await proxy.getItemConversationIndexAsync();
	} catch (err: any) {
		if (err.code === 7000) {
			logger.debug({
				message: 'proxy.getItemConversationIndexAsync() is unsupported in this environment',
				properties: { source: 'getOpinionBodyBuilder.ts', method: 'getOpinionBodyBuilder' }
			});
		}
	}

	requestBody.classification = {
		selectedLabelId: selectedLabelId || msgProperties.labels?.defaultEmailLabelId!,
		defaultLabelId: msgProperties.labels?.defaultEmailLabelId!
	};

	requestBody.keywords = (msgProperties.keywords ?? []).filter((keyword) => !keyword.startsWith('_'));
	requestBody.captureHashes = (msgProperties.capturedMatchHashes ?? []);

	return requestBody;
};
