import { AttachmentDetailsInterface } from '@common/interfaces';
import AttachmentDetailsCompose = Office.AttachmentDetailsCompose;

function makeArrayUnique(array: string[]) {
	function onlyUnique(value: any, index: any, self: string | any[]) {
		return self.indexOf(value) === index;
	}
	return array.filter(onlyUnique);
}

function convertBase64ToBlob(base64: string) : Blob {
	const binString = atob(base64);
	const byteArray = new Uint8Array(binString.length);
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < binString.length; ++i) {
		byteArray[i] = binString.charCodeAt(i);
	}
	return new Blob([byteArray]);
}

function formatFileSize(bytes: number, decimalPoint: number) {
	if (!bytes) return '0Bytes';
	const k = 1024;
	const dm = decimalPoint || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
}

function stringToByteArray(str: string) {
	const byteArray = new Uint8Array(str.length);
	for (let i = 0; i < str.length; i += 1) {
		byteArray[i] = str.charCodeAt(i);
	}
	return byteArray;
}

function arrayToBase64(buffer: Uint8Array) {
	let binary = '';
	const bytes = buffer;
	const len = bytes.byteLength;
	for (let i = 0; i < len; i += 1) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

function arrayBufferToBase64(buffer: any) {
	const bytes = new Uint8Array(buffer);
	return arrayToBase64(bytes);
}

function getIconName(fileName: string) {
	let icon : string = '';
	const fileExtension = fileName.split('.')?.pop()?.toLowerCase() ?? 'txt';

	switch (fileExtension) {
		case 'txt': case 'doc': case 'xlsx': case 'pdf': case 'pptx':
			icon = 'fa-solid fa-file';
			break;
		case 'png': case 'jpeg': case 'jpg': case 'gif': case 'svg': case 'webp': case 'bmp':
			icon = 'fa-solid fa-file-image';
			break;
		case 'mp4': case 'mpeg-4': case 'mov': case 'avi': case 'mkv':
			icon = 'fa-solid fa-file-video';
			break;
		case '7z': case 'xz': case 'bzip2': case 'gzip':
		case 'tar': case 'zip': case 'wim': case 'rar':
			icon = 'fa-solid fa-file-zip';
			break;
		default:
			icon = 'fa-solid fa-file';
	}
	return icon;
}
function addAttachmentIcons(attachments: Array<AttachmentDetailsInterface>) {
	if (!attachments) {
		return [];
	}
	return attachments.map((attachment) => {
		const icon = getIconName(attachment.fileName);
		return {
			...attachment,
			icon
		};
	});
}

function addNativeAttachmentIcons(attachments: Array<AttachmentDetailsCompose>) {
	if (!attachments) {
		return [];
	}
	return attachments.map((attachment) => {
		const icon = getIconName(attachment.name);

		return {
			...attachment,
			icon
		};
	});
}

export {
	makeArrayUnique,
	convertBase64ToBlob,
	formatFileSize,
	stringToByteArray,
	arrayToBase64,
	arrayBufferToBase64,
	addAttachmentIcons,
	addNativeAttachmentIcons
};
