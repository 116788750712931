import hash from 'object-hash';
import { CLIENT_STATE } from '@common/constants';
import { ClientStateInterface } from '@common/interfaces';
import IndexedDbRepository from '@common/dbStorage/indexedDbStore';

/* eslint-disable no-undef */
export default class ClientStateInfoStore {
	store: IndexedDbRepository<ClientStateInterface>;

	private hash: string = hash(CLIENT_STATE);

	/**
	 * Create an instance of the ClientStateInfoStore class
	 * @param {string | null} storeName Provide a name to give this clientStateInfo store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'client-state-info-store');
	}

	/**
	 * Retrieves the value of the ClientStateInfo with the given hash
	 * @param {string} hash The hash of the clientStateInfo to retrieve
	 * @returns {object | StorageError}
	 */
	async getClientStateInfo(): Promise<any | null> {
		const found = await this.store.get(this.hash);
		if (!found) return null;
		return found;
	}

	/**
	 * Updates the value of the clientStateInfo with the given hash
	 * @param hash The hash representation of the email
	 * @param {object} clientStateInfo The value to update the clientStateInfo with
	 * @returns {void | StorageError}
	 */
	async updateClientStateInfo(
		clientStateInfo: ClientStateInterface | null
	): Promise<void> {
		await this.store.update(this.hash, clientStateInfo);
	}

	/**
	 * Removes the clientStateInfo with the given hash from the store.
	 * Does nothing if such an clientStateInfo doesn't exist
	 * @param {string} hash The hash of the clientStateInfo to remove
	 * @returns {void | StorageError}
	 */
	async removeClientStateInfo(): Promise<void> {
		await this.store.remove(this.hash);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
