export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET_ERROR = 'LOGIN_RESET_ERROR';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const CLIENT_STATE_REFRESH_REQUESTED = 'CLIENT_STATE_REFRESH_REQUESTED';
export const CLIENT_STATE_REFRESH_SUCCESS = 'CLIENT_STATE_REFRESH_SUCCESS';
export const GET_LABELS_REQUESTED = 'GET_LABELS_REQUESTED';
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS';
export const GET_LABELS_FAILED = 'GET_LABELS_FAILED';
export const RBP_OPINION_REQUESTED = 'RBP_OPINION_REQUESTED';
export const RBP_OPINION_SUCCESS = 'RBP_OPINION_SUCCESS';
export const RBP_OPINION_FAILED = 'RBP_OPINION_FAILED';
export const RBP_OPINION_RESET = 'RBP_OPINION_RESET';
export const STORE_OPINION_CONTEXT_ID = 'STORE_OPINION_CONTEXT_ID';
export const AUTH_TOKEN_RENEW_REQUESTED = 'AUTH_TOKEN_RENEW_REQUESTED';
export const AUTH_TOKEN_RENEW_SUCCESS = 'AUTH_TOKEN_RENEW_SUCCESS';
export const AUTH_TOKEN_RENEW_FAILED = 'AUTH_TOKEN_RENEW_FAILED';
export const STORE_DIAGNOSTIC_INFORMATION = 'STORE_DIAGNOSTIC_INFORMATION';
export const SET_THEME = 'SET_THEME';
export const UPDATE_USER_THEME = 'UPDATE_USER_THEME';
export const UPDATE_OFFICE_THEME = 'UPDATE_OFFICE_THEME';
export const SET_OFFICE_THEME = 'SET_OFFICE_THEME';
export const STORE_REST_ID = 'STORE_REST_ID';
export const GET_CONFIG_REQUESTED = 'GET_CONFIG_REQUESTED';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILED = 'GET_CONFIG_FAILED';
export const UPDATE_POLICIES_REQUESTED = 'UPDATE_POLICIES_REQUESTED';
export const UPDATE_POLICIES_SUCCESS = 'UPDATE_POLICIES_SUCCESS';
export const UPDATE_POLICIES_FAILED = 'UPDATE_POLICIES_FAILED';
