import { theme } from '@common/enums';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const userModule = (defaults: any) => ({
	state: {
		auditOptions: defaults.auditOptions ?? 0,
		displayName: '',
		switchId: defaults.switchId ?? '',
		organization: defaults.organization ?? '',
		server: defaults.server ?? '',
		theme: defaults.theme ?? theme.Automatic,
		officeTheme: defaults.officeTheme ?? null,
		isPreventClient: defaults.isPreventClient ?? false,
		isProtectClient: defaults.isProtectClient ?? false,
		largeFileTransferEnabled: defaults.largeFileTransferEnabled ?? false,
		shouldEncryptCompleteMessage: defaults.shouldEncryptCompleteMessage ?? false,
		shouldPerformContentAnalysis: defaults.shouldPerformContentAnalysis ?? false
	},
	mutations,
	getters,
	actions
});

export default userModule;
