import AuthError from '@common/errors/authError';
import ApiError from '@common/errors/apiError';
import FetchError from '@common/errors/fetchError';
import { LabelsInterface, UserPoliciesInterface } from '@common/interfaces';
import { buildGetRequestOption } from '@common/httpClient/buildRequestOptions';

/**
 * Load classifiation labels for the current user
 * @param authToken The authentication token for the current user
 * @returns {Object}
 */
async function getClassificationLabels(
	authToken: string
): Promise<LabelsInterface> {
	try {
		const response = await fetch(
			'/api/v2/account/classification-policy',
			buildGetRequestOption(authToken)
		);
		const responseData = await response?.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 401:
					throw new AuthError(
						'Loading classification labels failed. Users session has either expired or is invalid.',
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
				default:
					throw new ApiError(
						`Loading classification labels failed. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof AuthError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent', 'api.errors.fetchFailed');
	}
}

/**
 * Refresh classification, dem and threat protection policies for the current user
 * Return updated subsection of classifications labels and threat protection policies
 * @param authToken The authentication token for the current user
 */
async function refreshPolicies(
	authToken: string
): Promise<UserPoliciesInterface> {
	try {
		const response = await fetch(
			'/api/v2/account/refresh-policy',
			buildGetRequestOption(authToken)
		);
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 401:
					throw new AuthError(
						'Refreshing policies failed. Users session has either expired or is invalid.',
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
				default:
					throw new ApiError(
						`Refreshing policies failed. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof AuthError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent', 'api.errors.fetchFailed');
	}
}

export {
	getClassificationLabels,
	refreshPolicies
};
